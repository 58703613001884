<template >
  <div name="dynamicfile">
    <div
      style="width: 100%; text-align: center"
      class="spin-holder"
      v-if="loading"
    >
      <i class="spinner fa fa-atom fa-spin"></i>
      <p>Loading File</p>
    </div>

    <div v-else>
      <div v-if="fileAllowed">
        <iframe
          v-bind:src="pathUrl"
          width="100%"
          height="900px"
          v-if="isImage == false"
        />
        <img
          :src="pathUrl"
          :alt="name"
          class="gallery-image"
          ref="reference"
          :title="name"
          @contextmenu.prevent="handler"
          v-else
        />
      </div>
      <p v-else>File not permitted on this platform</p>
    </div>
    <div class="hidebtn">&nbsp;</div>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";

//import { getLink } from "../assets/js/file-upload.teach.service"; // teacher service

export default {
  name: "dynamicfile",
  props: {
    url: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    extension() {
      return this.url.split(".").pop();
    },
    isImage() {
      let imageExtensions = ["jpg", "png"];
      return imageExtensions.includes(this.extension);
    },
    fileAllowed() {
      return true; //this.currentUser.schoolStyle != 'lancashire'
    },
  },
  data: function () {
    return {
      pathUrl: "",
      loading: true,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      // setTimeout(() => {
      //   getLink(this.url)
      //     .then((f) => {
      //       var encodedUrl = encodeURIComponent(f);
      //       let file = (this.pathUrl =
      //         this.isImage == true || this.extension == "pdf"
      //           ? f
      //           : "https://docs.google.com/viewer?url=" +
      //             encodedUrl +
      //             "&embedded=true");
      //       this.loading = false;
      //     })
      //     .catch((err) => {
      //       this.loading = false;
      //       console.log(err);
      //     });
      // }, 1000);
    },
  },
  components: {},
};
</script>

<style scoped>
.document {
  padding-top: -50px;
}

.modal-container {
  width: 90%;
}

.toolbarButton {
  display: none;
}

.hidebtn {
  width: 100px;
  height: 30px;
  background: #ff0000;
  position: absolute;
  left: 0px;
  top: -900px;
  display: none;
}
</style>

